<template>
  <div class="layout-footer">
    <!-- <img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
    por -->
    <a class="font-medium ml-2" href="https://sitbolivia.com/" target="_blank"><strong> SIT-BOLIVIA
			VERSI&Oacute;N : 1.0.45</strong></a>
  </div>
</template>

<script>
	export default {
		name: "AppFooter",
		/* methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			}
		}, */
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>
<style scoped lang="scss">
.a {
    color: rgb(1, 28, 39);
}
</style>